export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const AUTH_PEDNING = "AUTH_PEDNING";

export const UPDATE_REPOSITORIES = "UPDATE_REPOSITORIES";
export const UPDATE_COLLECTIONS = "UPDATE_COLLECTIONS";
export const COLLECTION_PENDING = "COLLECTION_PENDING";
export const COLLECTION_FAILED = "COLLECTION_FAILED";
export const COLLECTION_CLEAR = "COLLECTION_CLEAR";

export const REFRESH_DATA = "REFRESH_DATA";
